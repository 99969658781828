<template>
  <div class="borrowing">
    <div class="borrowing-t">
      <div class="borrowing-tl">
        <v-dropDownMenu
          v-model="requestData.itemTypeCode"
          :option1="TypeCodeList"
          @change="change"
        ></v-dropDownMenu>
      </div>
      <div class="borrowing-tr">
        <div class="input">
          <v-input v-model="requestData.itemName"></v-input>
        </div>
        <div class="btn" @click="search">搜索</div>
      </div>
    </div>
    <div class="borrowing-b">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
        :commonOperaParams="commonOperaParams"
      >
        <div class="borrowing-box">
          <div
            class="item"
            @click="toDetail(item)"
            v-for="(item, index) in listData"
            :key="index"
          >
            <div class="item-t">
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="item-c">{{ item.itemName }}</div>
            <div class="item-b">
              <div class="item-bl">
                可借<span>{{ item.itemNum }}件</span>
              </div>
              <div class="item-br">已借{{ item.borrowedTimes }}次</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import {
  getBorrowRecordsByIdUrl,
  findBorrowRecordsPageUrl,
  changeStatusUrl,
  getBorrowItemsByIdUrl,
  findBorrowItemsPageUrl,
  saveOrUpdateBorrowItemsUrl,
  removeBorrowItemsByIdUrl,
  getItemTypeCodeDictUrl,
} from "./api.js";
import {} from "./map.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
import { mapHelper } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "talent",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        itemName: "",
        itemTypeCode: undefined,
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
      TypeCodeList: [],
      TypeCodeListMap: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.getItemTypeCodeDict();
    // gloabalCount("", 20, 1);
  },
  methods: {
    init() {
      this.listData = [];
      this.requestData.curPage = 1;
      this.getDataList();
    },
    search() {
      this.init();
    },
    change(value) {
      this.requestData.itemTypeCode = value;
      this.init();
    },
    async getItemTypeCodeDict() {
      let res = await this.$axios.get(`${getItemTypeCodeDictUrl}`);
      if (res.code === 200) {
        res.data.data.forEach((ele) => {
          let obj = {};
          obj.text = ele.dictValue;
          obj.value = ele.dictKey;
          this.TypeCodeList.push(obj);
        });
        this.TypeCodeList.unshift({
          text: "全部",
          value: undefined,
        });
        const { map: oTypeCodeListMap, setOps: TypeCodeListOps } =
          mapHelper.setMap(this.TypeCodeList);
        this.TypeCodeListMap = oTypeCodeListMap;
      }
    },
    async submit() {
      if (await toRegister(this.$route.path, this.$route.query, "运动打卡")) {
      }
    },
    async getDataList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        // userId: this.userId,
        // houseId: this.houseId || this.communityId || undefined,
        // tenantId: this.tenantId,
        itemName: this.requestData.itemName,
        itemTypeCode: this.requestData.itemTypeCode,
      };
      let res = await this.$axios.get(`${findBorrowItemsPageUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          let arr = [];
          if (item.itemImgUrl) {
            arr = item.itemImgUrl.split(",");
          }
          if (arr[0]) {
            item.imgUrl = this.$handleImg(332, 332, arr[0]);
          }
          this.listData.push(item);
        });
      } else {
      }
    },
    onLoad() {
      this.getDataList();
    },
    async toDetail(item) {
      let params = {
        id: item.id,
      };
      this.$router.push({
        name: "borrowingDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.borrowing {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .borrowing-t {
    padding: 24px 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    .borrowing-tl {
      width: 260px;
      height: 66px;
      border-radius: 10px;
      border: 2px solid #8a95a6;
      .v-dropDownMenu {
        height: 100%;
        /deep/ .van-dropdown-menu {
          .van-dropdown-menu__title {
            font-size: 28px;
            font-weight: 400;
            color: #a0a6b9;
            line-height: 66px;
          }
          height: 100%;
          .van-dropdown-menu__bar {
            height: 100%;
          }
          .van-dropdown-menu__title {
            width: 100%;
            &::after {
              right: 13px !important;
              border: 0;
              font-size: 0;
              width: 28px;
              height: 18px;
              background: url("./img/arrow.png") no-repeat;
              background-size: 100% 100%;
              transform: none;
            }
          }
          .van-dropdown-menu__bar--opened {
            .van-dropdown-menu__title {
              &::after {
                margin: -3px;
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      /deep/ .van-dropdown-menu__bar {
        background: none;
      }
    }
    .borrowing-tr {
      width: 404px;
      height: 66px;
      background: #ffffff;
      border-radius: 37px;
      border: 4px solid #8a95a6;
      display: flex;
      justify-content: space-between;
      .input {
        flex: 1;
        /deep/ .van-cell {
          height: 66px;
          box-sizing: border-box;
          padding: 0 20px;
          border-radius: 37px;
          line-height: 66px;
        }
      }
      .btn {
        width: 150px;
        height: 66px;
        background: #8a95a6;
        border-radius: 37px;
        box-sizing: border-box;
        border: 2px solid #8a95a6;
        font-size: 28px;
        font-weight: 600;
        color: #ffffff;
        line-height: 66px;
        text-align: center;
      }
    }
  }
  .borrowing-b {
    padding-top: 130px;
    .borrowing-box {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      flex-wrap: wrap;
    }
    .item {
      flex-shrink: 0;
      margin-bottom: 26px;
      width: 332px;
      .item-t {
        width: 332px;
        height: 332px;
        border-radius: 16px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
      }

      .item-c {
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
        margin: 14px 0 12px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-b {
        display: flex;
        align-items: center;
        .item-bl {
          font-size: 24px;
          font-weight: 400;
          color: #e02020;
          line-height: 34px;
          span {
            font-size: 32px;
            font-weight: 700;
          }
        }
        .item-br {
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
          margin-left: 24px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.borrowing {
}
</style>
